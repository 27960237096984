define("a24-staffshift-subdomain-sysadmin/components/input-form-text-area", ["exports", "a24-ember-lib/components/input-form-text-area"], function (exports, _inputFormTextArea) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _inputFormTextArea.default;
    }
  });
});