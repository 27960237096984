define("a24-staffshift-subdomain-sysadmin/components/left-menu-with-title-bar-wrapper", ["exports", "a24-ember-menu/components/left-menu-with-title-bar-wrapper"], function (exports, _leftMenuWithTitleBarWrapper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _leftMenuWithTitleBarWrapper.default;
    }
  });
});