define('a24-staffshift-subdomain-sysadmin/services/rest-agency-group-candidate-policy-group', ['exports', 'a24-ember-policy-library/services/rest-agency-group-candidate-policy-group'], function (exports, _restAgencyGroupCandidatePolicyGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restAgencyGroupCandidatePolicyGroup.default;
    }
  });
});