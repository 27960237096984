define('a24-staffshift-subdomain-sysadmin/services/user-session', ['exports', 'a24-ember-staffshift-core/services/user-session'], function (exports, _userSession) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _userSession.default;
    }
  });
});