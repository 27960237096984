define("a24-staffshift-subdomain-sysadmin/mixins/did-render-changes-mixin", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _didRenderChangesMixin.default;
    }
  });
});