define("a24-staffshift-subdomain-sysadmin/routes/index/policy-type-view", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function () {},

        activate: function () {
            this._super(...arguments);
            Ember.run.schedule("afterRender", () => {
                $(".a24-bread-crumb-bar-component").show();
            });
        }
    });
});