define("a24-staffshift-subdomain-sysadmin/components/round-icon-component", ["exports", "a24-ember-lib/components/round-icon-component"], function (exports, _roundIconComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _roundIconComponent.default;
    }
  });
});