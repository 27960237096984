define('a24-staffshift-subdomain-sysadmin/services/a24-strings-form-inputs', ['exports', 'a24-ember-form-inputs/services/a24-strings-form-inputs'], function (exports, _a24StringsFormInputs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _a24StringsFormInputs.default;
    }
  });
});