define("a24-staffshift-subdomain-sysadmin/components/collapse-section-component", ["exports", "a24-ember-lib/components/collapse-section-component"], function (exports, _collapseSectionComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _collapseSectionComponent.default;
    }
  });
});