define("a24-staffshift-subdomain-sysadmin/components/loader-blackout", ["exports", "a24-ember-lib/components/loader-blackout"], function (exports, _loaderBlackout) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _loaderBlackout.default;
    }
  });
});