define("a24-staffshift-subdomain-sysadmin/routes/index", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function () {
            //At this point all needed js and css should have been loaded for the app and we can remove the app loader.
            //Better to have it here since this will display the loader until the menu service call is also done
            //The internal loading per page then displays until service calls are done
            Ember.$("#a24AppLoading").remove();
        }
    });
});