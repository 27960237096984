define('a24-staffshift-subdomain-sysadmin/components/title-bar', ['exports', 'a24-ember-title-bar/components/title-bar'], function (exports, _titleBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _titleBar.default;
    }
  });
});