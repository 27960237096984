define("a24-staffshift-subdomain-sysadmin/services/a24-strings-subdomain-sysadmin", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,
        init: function () {
            this._super(...arguments);

            this.set("_objStrings", {

                //===== STRINGS START =====

                // Temp until pages are implemented
                policies: "Policies"

                //===== STRINGS END   =====

                //===== TOKEN STRINGS START =====
                //===== TOKEN STRINGS END   =====

            });
        }
    });
});