define('a24-staffshift-subdomain-sysadmin/components/card-datagrid-filter', ['exports', 'a24-ember-lib/components/card-datagrid-filter'], function (exports, _cardDatagridFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cardDatagridFilter.default;
    }
  });
});