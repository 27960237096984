define("a24-staffshift-subdomain-sysadmin/components/navigation-bar", ["exports", "a24-ember-menu/components/navigation-bar"], function (exports, _navigationBar) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _navigationBar.default;
    }
  });
});