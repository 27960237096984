define("a24-staffshift-subdomain-sysadmin/components/input-form-date-picker", ["exports", "a24-ember-lib/components/input-form-date-picker"], function (exports, _inputFormDatePicker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _inputFormDatePicker.default;
    }
  });
});