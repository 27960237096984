define('a24-staffshift-subdomain-sysadmin/helpers/equals', ['exports', 'a24-ember-lib/helpers/equals'], function (exports, _equals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _equals.default;
    }
  });
  Object.defineProperty(exports, 'equals', {
    enumerable: true,
    get: function () {
      return _equals.equals;
    }
  });
});