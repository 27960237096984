define('a24-staffshift-subdomain-sysadmin/components/card-datagrid-selected-item', ['exports', 'a24-ember-lib/components/card-datagrid-selected-item'], function (exports, _cardDatagridSelectedItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cardDatagridSelectedItem.default;
    }
  });
});