define('a24-staffshift-subdomain-sysadmin/services/fab-button', ['exports', 'a24-ember-lib/services/fab-button'], function (exports, _fabButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fabButton.default;
    }
  });
});