define("a24-staffshift-subdomain-sysadmin/components/page-placeholder", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        _objSubdomainSysAdminStringsService: Ember.inject.service("a24-strings-subdomain-sysadmin"),
        _objTitleBarService: Ember.inject.service("title-bar"),

        init: function () {
            this._super(...arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSubdomainSysAdminStringsService").getString("policies"));
        }
    });
});