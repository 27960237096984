define("a24-staffshift-subdomain-sysadmin/routes/application", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbar: Ember.inject.service("snackbar"),
        _objUrlTrickery: Ember.inject.service("url-trickery"),
        beforeModel: function () {
            this.get("_objNavigation").set("objApplicationController", this);
            this.get("_objUrlTrickery");
            var objThis = this;

            window.addEventListener("message", function (e) {
                // Get event data
                var objData = e.data;

                // If data is not empty
                if (!a24Core.isEmpty(objData)) {
                    // Get details from event
                    var sIFrameName = objData.sIFrameName;
                    var iHeight = objData.iHeight;
                    var bFullscreen = objData.sIFrameType === "fullscreen";
                    var sEventType = objData.sEventType;
                    // If the event type was specified and it was setHeight
                    if (!a24Core.isEmpty(sEventType) && sEventType === "setHeight") {
                        // Set the height on the appropriate iframe
                        var objIFrame = $("iframe[name=" + sIFrameName + "]").eq(0);
                        // If the iframe is not empty
                        if (!a24Core.isEmpty(objIFrame)) {
                            if (bFullscreen) {
                                if (iHeight >= 0) {
                                    objIFrame.css("min-height", iHeight);
                                }
                                var iWindowHeight = $(window).height();
                                var iViewPortHeight = iWindowHeight - objIFrame.offset().top;
                                objIFrame.height(iViewPortHeight);
                            } else {
                                objIFrame.height(iHeight);
                            }
                        }
                    } else if (sEventType === "scrollEvent") {
                        // Main Continer top offset removed for accuracy since the container begins underneath it
                        var iPosition = objData.iPosition - $(".main-container").eq(0).offset().top;

                        var sUrl = window.location.href;

                        if (sUrl.match("ember\\d{1,}=r-pop")) {
                            // Scroll the iframe in the popup
                            var objPopupIFrameContainer = $(".modal.show").eq(0).find(".shadow-scroller").eq(0);
                            objPopupIFrameContainer.scrollTop(iPosition);
                        } else {
                            // Scroll the main container
                            $(".main-container").scrollTop(iPosition);
                        }
                    } else if (sEventType === "snackbarEvent") {
                        var sSnackBarText = objData.sSnackBarText;
                        objThis.get("_objSnackbar").showSnackbarMessage(sSnackBarText);
                    }
                }
            }, false);
        },
        afterModel: function () {}
    });
});