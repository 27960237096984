define("a24-staffshift-subdomain-sysadmin/components/collapsible-left-menu-item", ["exports", "a24-ember-menu/components/collapsible-left-menu-item"], function (exports, _collapsibleLeftMenuItem) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _collapsibleLeftMenuItem.default;
    }
  });
});